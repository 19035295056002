import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const DenetimKurulu = () => {
  const kuruluUye = [
    {
      fullname: "Sametcan KARAMAN",
      role: "Denetim Kurulu Başkanı",
      img: "/img/uyeler/samet-can-karaman.jpg",
    },
    {
      fullname: "Yılmaz SEYİDOĞLU",
      role: "Denetim Kurulu Üyesi",
      img: "/img/bayrak-logo.jpeg",
    },
    
  ];
  return (
    <PageLayouts>
      <MetaData title={"Denetim Kurulu"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Denetim Kurulu
        </div>
      </div>

      {/* <div
        className="col-md-3 border rounded-2"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        style={{ marginBottom: "20px" }}
      >
        <img src={uye.img} alt="" className="kurul-card" />
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h6
            className="p-1 poppins-medium"
            style={{
              color: "#1f1f1f",
              fontSize: "10px",
              marginTop: "15px",
            }}
          >
            {uye.fullname}
          </h6>
          <span
            className="p-1 mb-3"
            style={{ color: "#5c5c5c", fontSize: "12px" }}
          >
            {uye.role}
          </span>
        </div>
      </div> */}

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="">
          <div className="baskanlik-anasayfa-alt-haber ">
            <div className="row gap-5 d-flex justify-content-center align-items-center">
              <div className="container">
                <div className="row">
                  {kuruluUye?.map((uye) => (
                    <div className="col-sm-12 col-md-6 col-lg-3 kurul-card-wrapper mb-4">
                      <div className="rounded-2 shadow">
                        <img src={uye?.img} alt={`${uye?.fullname}-img`} className="rounded-1 kurul-card" />
                        <div className="kurul-card-shadow" style={{height: "1px", backgroundColor: "#d3bebe", width: "100%"}} />
                        <div className="d-flex flex-column justify-content-center align-items-center border-t" >
                          <h1 className="mt-4" style={{color: "#1F1F1F", fontSize: "13px"}}>{uye?.fullname}</h1>
                          <p className="" style={{color: "#BBB"}}>{uye?.role}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default DenetimKurulu;
