import React, { useState } from "react";

const Footer = () => {
  const [showFooter, setShowFooter] = useState({ show: false, id: "" });

  const handleClick = ({ show, id }) => {
    setShowFooter({ show, id });
  };
  return (
    <div id="footer-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{ padding: "25px" }}>
            <div id="footer-linkler-alani">
              <div id="fl-baskanlik">
                <span style={{cursor: "pointer"}}>
                  <a
                    onClick={() =>
                      showFooter?.show
                        ? handleClick({ show: false, id: "" })
                        : handleClick({ show: true, id: "fl-baskanlik" })
                    }
                  >
                    ANASAYFA
                    <img src="/img/footer_asaga_ok.png" className="" />
                  </a>
                </span>
                {showFooter?.show && showFooter?.id === "fl-baskanlik" && (
                  <ul
                    id="tbmmBaskanligi"
                    style={{ display: "block", background: "#fff" }}
                  >
                    <li className="border-bottom border-dark pb-1 mx-2">
                      <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}}>
                        <i className="icon-caret-right font-black text-dark"></i>
                        HALKLA İLİŞKİLER
                      </a>
                    </li>
                    <li className="border-bottom border-dark pb-1 mx-2">
                      <a href="/haberler" style={{color: "#39608B", cursor: "pointer"}}>
                        <i className="icon-caret-right"></i>HABERLER VE
                        BÜLTENLER
                      </a>
                    </li>
                    <li className="border-bottom border-dark pb-1 mx-2">
                      <a href="/tuzuk/tuzuk" style={{color: "#39608B", cursor: "pointer"}}>
                        <i className="icon-caret-right"></i>TÜZÜK
                      </a>
                    </li>
                    <li className="border-bottom border-dark pb-1 mx-2">
                      <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}}>
                        <i className="icon-caret-right"></i>KARARLAR BİLGİ
                        SİSTEMİ
                      </a>
                    </li>
                    <li className="pb-1 mx-2 mb-2">
                      <a href="/baskan/iletisim" style={{color: "#39608B", cursor: "pointer"}}>
                        <i className="icon-caret-right"></i>E POSTA
                      </a>
                    </li>
                  </ul>
                )}
              </div>

              <div id="fl-idari-teskilat">
                <span style={{cursor: "pointer"}}>
                  <a
                    onClick={() =>
                      showFooter?.show
                        ? handleClick({ show: false, id: "" })
                        : handleClick({ show: true, id: "fl-idari-teskilat" })
                    }
                  >
                    KURUMSAL
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                {showFooter?.show && showFooter?.id === "fl-idari-teskilat" && (
                <ul id="idariTeskilat" style={{ display: "block", background: "#fff" }}>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/baskan/ozgecmis" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>GENEL BAŞKAN
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/baskan/yonetim-kurulu" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>YÖNETİM KURULU
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/baskan/denetim-kurulu" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>DENETİM KURULU
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/haberler" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>HABERLER
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/baskan/mesajlar" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>MESAJLAR
                    </a>
                  </li>
                  <li className="px-2">
                    <a href="/baskan/iletisim" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>İLETİŞİM
                    </a>
                  </li>
                </ul>
                )}
              </div>

              <div id="fl-tbmm-ziyaret">
                <span style={{cursor: "pointer"}}>
                  <a
                     onClick={() =>
                      showFooter?.show
                        ? handleClick({ show: false, id: "" })
                        : handleClick({ show: true, id: "fl-tbmm-ziyaret" })
                    }
                  >
                    ETKİNLİKLER
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                {showFooter?.show && showFooter?.id === "fl-tbmm-ziyaret" && (
                <ul id="ziyaretEdin" style={{ display: "block", background: "#fff" }}>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/etkinlikler/bagis" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>BAĞIŞ ETKİNLİKLERİ
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/etkinlikler/vakif" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>VAKIF ETKİNLİKLERİ
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>BASIN AÇIKLAMALARI
                    </a>
                  </li>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>TANIŞMA ETKİNLİKLERİ
                    </a>
                  </li>
                  <li className="px-2">
                    <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>RESMİ ZİYARETLER
                    </a>
                  </li>
                </ul>
                )}
              </div>

              <div id="fl-engelsiz-meclis">
                <span style={{cursor: "pointer"}}>
                  <a
                     onClick={() =>
                      showFooter?.show
                        ? handleClick({ show: false, id: "" })
                        : handleClick({ show: true, id: "fl-engelsiz-meclis" })
                    }
                  >
                    GÜNDEM
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                {showFooter?.show && showFooter?.id === "fl-engelsiz-meclis" && (
                <ul id="engelsizMeclis" style={{ display: "block", background: "#fff" }}>
                  <li className="border-bottom border-dark pb-1 mx-2">
                    <a href="/etkinlikler/vakif" style={{color: "#39608B", cursor: "pointer"}}>
                      <i className="icon-caret-right"></i>VAKIF GÜNDEMİ
                    </a>
                  </li>
                  <li className="px-2">
                    <a href="/yakinda" style={{color: "#39608B", cursor: "pointer"}} >
                      <i className="icon-caret-right"></i>ULUSAL GÜNDEM
                    </a>
                  </li>
                </ul>
                )}
              </div>
            </div>

            <div id="footer-end-line" className="" style={{paddingBottom: "400px"}}>
              <div id="footer-tbmm-logo-holder" className="ps-1">
                <a href="/" >
                  <img src="/img/logo-111.png" />
                </a>
              </div>

              <div id="footer-tbmm-text-holder"></div>

              <div id="footer-adres-area">
                <span id="footer-bize-ulasin-title">BİZE ULAŞIN</span>
                <address>
                  <div>
                    <span id="footer_home_icon">
                      <img src="/images/yeni_tasarim/tbmm_home_icon.png" />
                    </span>
                    Genel Merkez: Cinnah Caddesi No: 31/25 Çankaya/Ankara
                  </div>
                  <div>
                    <span>
                      <img src="/images/yeni_tasarim/tbmm_tel_icon.png" />
                    </span>
                    <a href="tel:+905362006038">0 (536) 200 60 38</a>
                  </div>
                  <div>
                    <span>
                      <img src="/images/yeni_tasarim/wp.png" />
                    </span>
                    <a href="https://wa.me/905362006038">0 (536) 200 60 38</a>
                  </div>
                  <div>
                    <span id="footer_email_icon">
                      <img src="/images/yeni_tasarim/tbmm_email_icon.png" />
                    </span>
                    <a href="mailto:info@turanocaklari.org.tr">
                      info@turanocaklari.org.tr
                    </a>
                  </div>
                </address>
              </div>

              <div id="footer-social-area" className="-">
                <a target="_blank" href="https://www.facebook.com/tuglar/">
                  <div>
                    <img
                      style={{ width: "19%" }}
                      src="/images/yeni_tasarim/Icon_facebook.png"
                    />
                  </div>
                </a>

                <a target="_blank" href="https://x.com/turanocaklarii">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_twitter.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/turanocaklarii/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_instagram.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  id="anasayfa-social-link-youtube"
                  href="https://www.youtube.com/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_youtube.png" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
